<template>
    <div class="details-container">
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.HEADER") }}:
                </span>
                <span>{{ details.citizenship }}</span>
            </div>
            <div class="col-md-7">
                <span class="d-block font-weight-bold">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.HEADER") }}:
                </span>
                <span>{{ details.person }}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">
                    {{ fields.personal_numeric_code.label }}:
                </span>
                <span>
                    {{ model[fields.personal_numeric_code.name] }}
                </span>
            </div>
            <div class="col-md-7">
                <span class="d-block font-weight-bold">{{ $t('BENEFICIARY.BENEFICIARY_DETAILS.CONTACT') }}:</span>
                <span class="d-block">{{ model[fields.email.name] }}</span>
                <span>{{ model[fields.phone_number.name] }} </span>
            </div>
        </div>
        <div class="row mb-6">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">{{ $t('BENEFICIARY.BENEFICIARY_DETAILS.ADDRESS') }}:</span>
                <span>{{ address }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { BeneficiaryModel } from "@/modules/applications/models/beneficiary-model";
import { FormSchema } from "@/shared/form/form-schema";

const { fields } = BeneficiaryModel;
const formSchema = new FormSchema([
    fields.personal_numeric_code,
    fields.email,
    fields.phone_number,
]);

export default {
    name: "CitizenDetails",
    props: {
        details: {
            type: Object,
        },
    },
    data() {
        return {
            fields,
        };
    },
    computed: {
        address() {
            let address = `${this.details.street} ${this.details.street_number}`;

            if (this.details.block) {
                address += `, ${this.details.block}`;
            }

            if (this.details.staircase) {
                address += `, ${this.details.staircase}`;
            }

            if (this.details.apartment) {
                address += `, ${this.details.apartment}`;
            }

            if (this.details.village) {
                address += `, ${this.details.village}`;
            }

            return `${address}, ${this.details.locality}, ${this.details.county}`;
        },
        model() {
            return formSchema.initialValues(this.details);
        }
    },
    methods: {},
    created() {},
};
</script>
