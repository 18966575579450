<template>
  <st-section :header="$t('APPLICATION.VIEW.APPLICANT_DETAILS_SECTION_TITLE')">
    <template #body>
      <citizen-applicant-form
        v-if="userType === 'citizen'"
        ref="applicantForm"
        @change="onModelChange"
        :viewMode="viewMode"
        />
      <legal-applicant-form
        v-if="userType === 'legal_entity'"
        ref="applicantForm"
        @change="onModelChange"
        :viewMode="viewMode"
        />
    </template>
  </st-section>
</template>

<script>
import { mapGetters } from "vuex";
import CitizenApplicantForm from "@/modules/applications/components/applicant/CitizenApplicantForm";
import LegalApplicantForm from "@/modules/applications/components/applicant/LegalApplicantForm";

export default {
  name: "ApplicantForm",
  components: {
    CitizenApplicantForm,
    LegalApplicantForm,
  },
  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      userType: 'citizen',
    };
  },
  computed: {
    ...mapGetters({
      record: "applications/form/record",
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    onModelChange(model) {
        this.$emit('changeList', model, 'applicant_form');
    },
  },
  created() {
    if (this.record && this.record.form && this.record.form.applicant_user) {
      this.userType = this.record.form.applicant_user.user_type_name || 'citizen';
    } else {
      this.userType = this.currentUser?.user_type_name || 'citizen';
    }
  },
};
</script>
