<template>
    <form class="form" novalidate="novalidate" id="st_real_estate_land_form">
        <loader v-if="isSyncLoading" />
        <div class="st-section-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.county_id.label }} *
                        </label>
                        <div>
                            <st-autocomplete
                                v-model="model[fields.county_id.name]"
                                :options="fields.county_id.options"
                                :ref="fields.county_id.name"
                                :name="fields.county_id.name"
                                :disabled="true"
                                :fields="fields"
                                :model="model"
                                :formValidation="fv"
                            >
                            </st-autocomplete>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.territorial_administrative_unit_id.label }} *
                        </label>
                        <div>
                            <st-autocomplete
                                v-model="model[fields.territorial_administrative_unit_id.name]"
                                :ref="fields.territorial_administrative_unit_id.name"
                                :name="fields.territorial_administrative_unit_id.name"
                                :options="fields.territorial_administrative_unit_id.options"
                                :disabled="viewMode"
                                :fields="fields"
                                :model="model"
                                :formValidation="fv"
                            >
                            </st-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.cadastral_number.label }} *
                        </label>
                        <div>
                            <st-input-text
                                :ref="fields.cadastral_number.name"
                                :name="fields.cadastral_number.name"
                                v-model="model[fields.cadastral_number.name]"
                                :disabled="viewMode || !hasUatSelected"
                                @blur="syncAncpi"
                                enableBlur
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.land_area.label }} *
                        </label>
                        <b-row>
                            <b-col cols="10">
                                <st-input-text
                                    :ref="fields.land_area.name"
                                    :name="fields.land_area.name"
                                    v-model="model[fields.land_area.name]"
                                    :placeholder="$t('PLACEHOLDERS.MP')"
                                    :disabled="true"
                                />
                            </b-col>
                            <b-col cols="2">
                                <st-button
                                    variant="light"
                                    customClass="text-primary shadow-sm p-3 mb-5 bg-white rounded"
                                    :callback="syncAncpi"
                                >
                                    <i class="fas fa-sync-alt text-primary"></i>
                                </st-button>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="col-12">
                    <span v-if="searchStatus === 'notfound'" class="text-danger">{{ $t('APPLICATION.TERRITORIAL_ATLAS.NOT_FOUND') }}</span>
                    <span v-if="searchStatus === 'error'" class="text-danger">{{ $t('APPLICATION.TERRITORIAL_ATLAS.ERROR') }}</span>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { RealEstateModel } from "@/modules/applications/models/real-estate-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapGetters, mapActions } from "vuex";
import Polygon from "@arcgis/core/geometry/Polygon";

const { fields } = RealEstateModel;

const formSchema = new FormSchema([
    fields.id,
    fields.cadastral_number,
    fields.county_id,
    fields.territorial_administrative_unit_id,
    fields.land_area,
]);
export default {
    name: "RealEstateLandForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            documents: [],
            files: [],
            polling: null,
            searchStatus: '',
            isSyncLoading: false,
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
            record: "applications/form/record",
            loading: 'shared/loading',
        }),
        hasUatSelected() {
            return !!this.model[this.fields.territorial_administrative_unit_id.name];
        },
    },
    methods: {
        ...mapActions({
            searchParcel: 'applications/form/searchParcel',
        }),
        async syncAncpi() {
            const { cadastral_number, territorial_administrative_unit_id } = this.model;
            if (cadastral_number && territorial_administrative_unit_id) {
                const payload = {
                    topo_number: cadastral_number,
                    siruta_code: territorial_administrative_unit_id,
                };
                try {
                    this.isSyncLoading = true;
                    const { data } = await this.searchParcel(payload);
                    this.searchStatus = data.status;
                    if (data.status === 'pending' || data.status === 'new') {
                        this.polling = setInterval(() => {
                            this.checkSearchStatus(payload);
                        }, 1000);
                    } else if (data.status === 'completed') {
                        this.model[fields.land_area.name] = data.area;
                        this.model['category_code'] = data.category_code;
                        this.model['coordinates'] = data.coordinates;
                        this.model['centroid'] = this.getCentroid(data.coordinates);
                    } else if (data.status === 'notfound') {
                        this.model[fields.land_area.name] = '';
                    } else if (data.status === 'error') {
                        this.model[fields.land_area.name] = '';
                    }
                } catch(error) {
                    this.model[fields.land_area.name] = '';
                    this.searchStatus = 'error';
                } finally {
                    if (this.searchStatus !== 'pending') {
                        this.isSyncLoading = false;
                    }
                }
            } else {
                // Revalidate fields
            }
        },
        async checkSearchStatus(payload) {
            try {
                const { data } = await this.searchParcel(payload);
                if (data?.status) {
                    this.searchStatus = data.status;
                    if (this.searchStatus === 'completed') {
                        this.model[fields.land_area.name] = data.area;
                        this.model['category_code'] = data.category_code;
                        this.model['coordinates'] = data.coordinates;
                        this.model['centroid'] = this.getCentroid(data.coordinates);
                        clearInterval(this.polling);
                    } else if (this.searchStatus === 'notfound') {
                        this.model[fields.land_area.name] = '';
                        clearInterval(this.polling);
                    } else if (this.searchStatus === 'error') {
                        this.model[fields.land_area.name] = '';
                        clearInterval(this.polling);
                    }
                }
            } catch(error) {
                this.model[fields.land_area.name] = '';
                this.searchStatus = 'error';
                clearInterval(this.polling);
            } finally {
                if (this.searchStatus !== 'pending') {
                    this.isSyncLoading = false;
                }
            }
        },
        getCentroid(coordinates) {
            const polygonRings = [];
            coordinates.forEach((el) => {
              polygonRings.push([el.x, el.y]);
            });

            const polygon = new Polygon({
              type: "polygon",
              rings: polygonRings,
              spatialReference: {
                wkid: 31700,
              },
            });

            const centerPointX = polygon.centroid.x;
            const centerPointY = polygon.centroid.y;

            return { x: centerPointX, y: centerPointY };
        }
    },
    created() {
        //hardcode Cluj as single option for county
        this.fields.county_id.options = this.fields.county_id.options.filter(
            (el) => {
                return el.value === this.defaultCountyId;
            }
        );
        if (this.item) {
            this.model = formSchema.initialValues(this.item);
        } else {
            const record = {
                real_estate_type: "land",
                county_id: this.defaultCountyId,
            };

            this.model = formSchema.initialValues(record);
        }
    },
    mounted() {
        this.fv = createFormValidation("st_real_estate_land_form", this.rules);
    },
    beforeDestroy () {
        if (this.polling) {
            clearInterval(this.polling);
        }
    },
};
</script>
