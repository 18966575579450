import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EmailField from '@/shared/fields/email-field';
import BooleanField from '@/shared/fields/boolean-field';

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);

const fields = {
    applicant_user_id: new IdField('id', 'Id'),
    license: new StringField('license', label('authorisation_code'), { required: true }),
    subsidiary: new StringField('subsidiary', label('subsidiary'), { required: true }),
    first_name: new StringField('first_name', label('first_name'), { required: true }),
    last_name: new StringField('last_name', label('last_name'), { required: true }),
    email: new EmailField('email', label('email'), { required: true }),
    phone_number: new StringField('phone_number', label('phone'), { required: true }),
    is_contact_person: new BooleanField('is_contact_person', label('is_contact_person')),
};

export class ApplicantModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
