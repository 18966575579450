<template>
  <div class="fm-style st-page">
    <b-form ref="generateForm" novalidate>
      <template v-for="item in data.list">

        <template v-if="item.type == 'grid'">
          <b-row
            :key="item.key"
            type="flex"
            :gutter="item.options.gutter ? item.options.gutter : 0"
            :justify="item.options.justify"
            :align="item.options.align"
          >
            <b-col v-for="(col, colIndex) in item.columns" :key="colIndex" :span="col.span">
              <template v-for="citem in col.list" >
                <generate-form-item
                  :key="citem.key"
                  :models.sync="models"
                  :rules="rules"
                  :widget="citem"
                  :viewMode="viewMode"
                  :partialEdit="partialEdit"
                  :validate="wasValidated"
                  :validation="wasValidated ? validation[citem.name] : null"
                  @input-change="onInputChange">
                </generate-form-item>
              </template>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="item.type === 'inline'">
          <div class="fm-inline-container" :key="item.key">
             <template v-for="(el) in item.list">
              <generate-form-item
                  :key="`form_gen_${el.key}`"
                  :models.sync="models"
                  :rules="rules"
                  :widget="el"
                  :viewMode="viewMode"
                  :partialEdit="partialEdit"
                  :validate="wasValidated"
                  :validation="wasValidated ? validation[el.name] : null"
                  @input-change="onInputChange">
                </generate-form-item>
            </template>
          </div>
        </template>
        <template v-else-if="item.type === 'section'">
          <st-section
            :key="item.key"
            :header="item.options.title"
            :collapsible="viewMode"
            :collapsibleId="item.key"
          >
            <template #body>
              <template v-for="(el) in item.list">
                <template v-if="el.type == 'grid'">
                  <b-row
                    :key="el.key"
                    type="flex"
                    :gutter="el.options.gutter ? el.options.gutter : 0"
                    :justify="el.options.justify"
                    :align="el.options.align"
                  >
                    <b-col v-for="(col, colIndex) in el.columns" :key="colIndex" :span="col.span">
                      <template v-for="citem in col.list" >
                        <generate-form-item
                          :key="citem.key"
                          :models.sync="models"
                          :rules="rules"
                          :widget="citem"
                          :viewMode="viewMode"
                          :partialEdit="partialEdit"
                          :validate="wasValidated"
                          :validation="wasValidated ? validation[citem.name] : null"
                          @input-change="onInputChange">
                        </generate-form-item>
                      </template>
                    </b-col>
                  </b-row>
                </template>
                <generate-form-item
                  v-else
                  :key="`form_gen_${el.key}`"
                  :models.sync="models"
                  :rules="rules"
                  :widget="el"
                  :viewMode="viewMode"
                  :partialEdit="partialEdit"
                  :validate="wasValidated"
                  :validation="wasValidated ? validation[el.name] : null"
                  @input-change="onInputChange">
                </generate-form-item>
              </template>
            </template>
          </st-section>
        </template>

        <template v-else-if="item.type === 'beneficiary-list'">
          <beneficiary-list
            ref="beneficiaryList"
            :key="`form_gen_${item.key}_beneficiary`"
            :viewMode="viewMode"
            @changeList="updateModel"
            :wasValidated="wasValidated"
          />
        </template>

        <template v-else-if="item.type === 'applicant-form'">
          <applicant-form
            ref="applicantForm"
            :key="`form_gen_${item.key}_applicant`"
            :viewMode="viewMode"
            @changeList="updateModel"
          />
        </template>

        <template v-else-if="item.type === 'real-estate-list'">
          <real-estate-list
            ref="realEstateList"
            :key="`form_gen_${item.key}_real_estate`"
            :viewMode="viewMode"
            :applicationType="value.application_type_id"
            :wasValidated="wasValidated"
            @changeList="updateModel"/>
        </template>

        <template v-else-if="item.type === 'decision-list'">
          <application-decision-list
            :viewMode="viewMode"
            ref="decisionList"
            :key="`form_gen_${item.key}_decision`"
            :wasValidated="wasValidated"
            @changeList="updateModel"/>
        </template>

         <template v-else-if="item.type === 'attachments'">
          <application-attachments
            :viewMode="viewMode"
            ref="attachments"
            :key="`form_gen_${item.key}_attach`"
            @changeList="updateModel">
          </application-attachments>
        </template>

        <template v-else-if="item.type === 'urbanism-regulation-picker'">
          <application-urbanism-regulation
            ref="urbanismRegulations"
            :key="`form_gen_${item.key}_rlu`"
            v-if="partialEdit">
          </application-urbanism-regulation>
        </template>

        <template v-else>
          <generate-form-item
            :key="`form_gen_${item.key}`"
            :models.sync="models"
            :rules="rules"
            :widget="item"
            :viewMode="viewMode"
            :partialEdit="partialEdit"
            :validate="wasValidated"
            :validation="wasValidated ? validation[item.name] : null"
            @input-change="onInputChange">
          </generate-form-item>
        </template>

      </template>
    </b-form>
  </div>
</template>

<script>
import GenerateFormItem from './GenerateFormItem';
import BeneficiaryList from '@/modules/applications/components/beneficiary/BeneficiaryList.vue';
import RealEstateList from '@/modules/applications/components/real-estate/RealEstateList.vue';
import ApplicantForm from '@/modules/applications/components/applicant/ApplicantForm.vue';

export default {
  name: 'fm-generate-form',
  components: {
    GenerateFormItem,
    BeneficiaryList,
    RealEstateList,
    ApplicantForm,
    ApplicationDecisionList: () => import('@/modules/applications/components/decision/ApplicationDecisionList.vue'),
    ApplicationAttachments: () => import('@/modules/applications/components/attachments/ApplicationAttachments.vue'),
    ApplicationUrbanismRegulation: () => import('@/modules/applications/components/urbanism-regulation/ApplicationUrbanismRegulation.vue'),
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    partialEdit: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      models: {},
      rules: {},
      modelOptions: {},
      validation: {},
      wasValidated: false,
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        this.generateModel(val.list);
      }
    },
    value: {
      deep: true,
      handler(val) {
        this.models = {...this.models, ...val};
      }
    }
  },
  created () {
    this.generateModel(this.data.list);
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('child-mounted');
    });
  },
  methods: {
    updateModel(data, key) {
      this.models[key] = data;
      this.$emit('modelUpdated', this.models[key], key);
    },
    generateModel (genList) {
      for (let i = 0; i < genList.length; i++) {
        if (genList[i].type === 'grid') {
          genList[i].columns.forEach(item => {
            this.generateModel(item.list);
          })
        } else if (genList[i].type === 'section' || genList[i].type === 'inline') {
          this.generateModel(genList[i].list);
        } else {
          if (this.value && Object.keys(this.value).indexOf(genList[i].name) >= 0) {
            this.models[genList[i].name] = this.value[genList[i].name];
          } else {
            this.models[genList[i].name] = genList[i].options.defaultValue;
          }

          if (genList[i].type === 'checkbox') {
            this.modelOptions[genList[i].name] = genList[i].options.options.map((el) => el.value);
          }

          if (genList[i].type === 'select') {
            this.modelOptions[genList[i].name] = {
              type: 'select',
              options: genList[i].options.options.map((el) => { return { value: el.value, label: el.label}})
            }
          }

          if (this.rules[genList[i].name]) {
            this.rules[genList[i].name] = [...this.rules[genList[i].name], ...genList[i].rules.map(item => {
              if (item.pattern) {
                return {...item, pattern: eval(item.pattern)};
              } else {
                return {...item};
              }
            })]
          } else {

            this.rules[genList[i].name] = [...genList[i].rules.map(item => {
              if (item.pattern) {
                return {...item, pattern: eval(item.pattern)}
              } else {
                return {...item}
              }
            })]
          }
        }
      }
    },
    getData (draft) {
      return new Promise(async (resolve, reject) => {
          let validateBeneficiaryList;
          let validateRealEstateList;
          let validateDecisionList;
          let validateApplicantForm;
          let isValidBeneficiaryList = true;
          let isValidRealEstateList = true;
          let isValidDecisionList = true;
          let isValidApplicantForm = true;

          if (this.$refs.beneficiaryList) {
            isValidBeneficiaryList = this.$refs.beneficiaryList[0].items.length > 0;
            validateBeneficiaryList = this.$refs.beneficiaryList[0].items;
          }

          if (this.$refs.realEstateList) {
            isValidRealEstateList = await this.$refs.realEstateList[0].items.length > 0;
            validateRealEstateList = this.$refs.realEstateList[0].items;
          }

          if (this.$refs.applicantForm) {
            const isValid = await this.$refs.applicantForm[0].$refs.applicantForm.fv.validate();
            isValidApplicantForm = isValid === 'Valid';
            validateApplicantForm = this.$refs.applicantForm[0].$refs.applicantForm.model;
          }

          if (this.$refs.decisionList) {
            isValidDecisionList = await this.$refs.decisionList[0].items.length > 0;
            validateDecisionList = this.$refs.decisionList[0].items;
          }

          let requiredFieldsValid = this.validateRequiredFields();

          if (draft) {
            isValidBeneficiaryList = true;
            isValidRealEstateList = true;
            isValidApplicantForm = true;
            isValidDecisionList = true;
            requiredFieldsValid = true;
            this.wasValidated = false;
          } else {
            this.wasValidated = true;
          }

          if (isValidBeneficiaryList && isValidRealEstateList && isValidApplicantForm && isValidDecisionList && requiredFieldsValid) {
            let clearModel = {};

            Object.keys(this.models).forEach((key) => {
              if (this.models[key] != undefined) {
                let initialValue = this.models[key];
                if (typeof initialValue === 'string') {
	                initialValue = initialValue.replace(/(?:\r\n|\n|\r)/g, '\r\n');
                }

                let obj = {
                  [key] : initialValue
                };

                clearModel = {
                  ...clearModel,
                  ...obj
                };
              }
            });

            const form = {
              ...clearModel,
            };

            if (validateRealEstateList) {
              form.real_estate_target = validateRealEstateList;
            }

            if (validateBeneficiaryList) {
              form.beneficiary_users = validateBeneficiaryList;
            }

            if (validateApplicantForm) {
                form.applicant_user = validateApplicantForm;
            }

            if (validateDecisionList) {
              form.decisions = validateDecisionList;
            }

            if (this.$refs.attachments?.documents?.length) {
              form.attachments = this.$refs.attachments;
            }

            const model = {
              form,
              options: this.modelOptions
            };
            resolve(model);
          } else {
            reject('VALIDATION_ERROR');

            this.$alert({
                type: 'warning',
                text: this.$t('FORMS.MESSAGE.VALID_ERROR'),
                showConfirmButton: false,
            });
          }
      });
    },
    reset () {
      this.$refs.generateForm.resetFields()
    },
    onInputChange (value, field) {
      if (this.wasValidated) {
          this.validateRequiredFields();
      }
      this.$emit('on-change', field, value, this.models)
    },
    refresh () {},
    validateRequiredFields() {
      const requiredFields = this.getRequiredFields();
      let isValid = true;
      this.validation = requiredFields.reduce((acc, cur) => ({ ...acc, [cur]: null }), {});
      requiredFields.forEach((field) => {
        if (!this.models[field] || !this.models[field]?.length) {
          isValid = false;
          this.validation[field] = false;
        } else {
            this.validation[field] = true;
        }
      });
      return isValid;
    },
    getRequiredFields() {
      let requiredFields = [];
      for (let rule in this.rules) {
        if (this.rules[rule].length > 0) {
          let validators = this.rules[rule].filter((el) => el?.required);
          if (validators.length) {
            requiredFields.push(rule);
          }
        }
      }

      return requiredFields;
    }
  },
}
</script>
