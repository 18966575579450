<template>
    <div>
        <citizen-details
            v-if="item.user_type_name === 'citizen'"
            :details="item"
        ></citizen-details>
        <foreigner-details
            v-if="item.user_type_name === 'foreigner'"
            :details="item"
        ></foreigner-details>
        <legal-entity-details
            v-if="item.user_type_name === 'legal_entity'"
            :details="item"
        ></legal-entity-details>
    </div>
</template>

<script>
import CitizenDetails from "./CitizenDetails";
import ForeignerDetails from "./ForeignerDetails";
import LegalEntityDetails from "./LegalEntityDetails";

export default {
    name: "BeneficiaryDetails",
    components: {
        CitizenDetails,
        ForeignerDetails,
        LegalEntityDetails,
    },
    props: {
        item: {
            type: Object,
        },
    },
};
</script>
