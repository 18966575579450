<template>
<div class="details-container">
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.HEADER") }}:
                </span>
                <span>{{ details.citizenship }}</span>
            </div>
            <div class="col-md-7">
                <span class="d-block font-weight-bold">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.HEADER") }}:
                </span>
                <span>{{ details.person }}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">
                    {{ fields.passport_number.label }}:
                </span>
                <span>
                    {{ model[fields.passport_number.name] }}
                </span>
            </div>
            <div class="col-md-7">
                <span class="d-block font-weight-bold">{{ $t('BENEFICIARY.BENEFICIARY_DETAILS.CONTACT') }}:</span>
                <span class="d-block">{{ model[fields.email.name] }}</span>
                <span>{{ model[fields.phone_number.name] }} </span>
            </div>
        </div>
        <div class="row mb-6">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">{{ $t('BENEFICIARY.BENEFICIARY_DETAILS.ADDRESS') }}:</span>
                <span>{{ address }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { BeneficiaryModel } from "@/modules/applications/models/beneficiary-model";
import { FormSchema } from "@/shared/form/form-schema";
const { fields } = BeneficiaryModel;

const formSchema = new FormSchema([
    fields.passport_number,
    fields.email,
    fields.phone_number,
]);

export default {
    name: "ForeignerDetails",
    components: {},
    props: {
        details: {
            type: Object,
        },
    },
    data() {
        return {
            fields,
        };
    },
    computed: {
        address() {
            return `${this.details.address}, ${this.details.city}, ${this.details.country}`;
        },
        model() {
            return formSchema.initialValues(this.details);
        }
    },
    created() {},
};
</script>
