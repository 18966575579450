<template>
  <b-form-group class="ml-5"
        v-if="isVisible"
        :state="formItemState"
        :invalid-feedback="invalidFeedback"
        :id="widget.id"
        >
        <div class="d-flex justify-content-between">
            <label v-if="widget.label && !widget.options.hideLabel"
                class="form-item-label"
                :class="{'is_req': !viewMode && widget.options.required}"
            >
                {{!widget.isCustom ? widget.label : ""}}
            </label>
            <i v-if="widget.options.showTooltip"
                class="fas fa-info-circle text-primary"
                v-b-popover.hover.auto="widget.toolTipText">
            </i>
        </div>
    <template v-if="widget.type == 'input'">
      <template v-if="elementAsView">
        <div class="form-control-view">{{ dataModel }}</div>
      </template>
      <b-form-input
        v-else
        v-model="dataModel"
        :style="{ width: widget.options.width }"
        :placeholder="widget.options.placeholder"
        :disabled="widget.options.disabled"
        :state="formItemState"
        :id="widget.name"
      ></b-form-input>
    </template>

    <template v-if="widget.type == 'textarea'">
      <template v-if="elementAsView">
        <div class="form-control-view">{{ dataModel }}</div>
      </template>
      <b-form-textarea
        v-else
        v-model.trim="dataModel"
        :style="{ width: widget.options.width }"
        :disabled="widget.options.disabled"
        :plaintext="widget.options.plaintext"
        :placeholder="widget.options.placeholder"
        :rows="widget.options.rows"
        :max-rows="widget.options.maxRows"
        :state="formItemState"
        :id="widget.name"
      ></b-form-textarea>
    </template>

    <template v-if="widget.type == 'number'">
      <template v-if="elementAsView">
        <div class="form-control-view">{{ dataModel }}</div>
      </template>
      <b-form-input
        v-else
        type="number"
        v-model="dataModel"
        :disabled="widget.options.disabled"
        :style="{ width: widget.options.width }"
        :state="formItemState"
        :id="widget.name"
      ></b-form-input>
    </template>

    <template v-if="widget.type == 'radio'">
      <template v-if="elementAsView">
        <div class="form-control-view">
          {{ radioAsView }}
        </div>
      </template>
      <b-form-radio-group
        v-else
        v-model="dataModel"
        :style="{ width: widget.options.width }"
        :disabled="widget.options.disabled"
        :stacked="!widget.options.inline"
        :state="formItemState"
        :id="widget.name"
      >
        <b-form-radio
          :value="item.value"
          v-for="(item, index) in widget.options.options"
          :key="item.value + index"
          :name="item.value"
        >
          {{ widget.options.showLabel ? item.label : item.value }}
        </b-form-radio>
      </b-form-radio-group>
    </template>

    <template v-if="widget.type == 'checkbox'">
      <template v-if="elementAsView">
        <ul
          v-for="(itemLabel, index) in checkboxAsView"
          :key="index"
          class="form-control-view"
          >
          <li>{{ itemLabel }}</li>
        </ul>
      </template>
      <b-form-checkbox-group
        v-else
        v-model="dataModel"
        :style="{ width: widget.options.width }"
        :disabled="widget.options.disabled"
        :stacked="!widget.options.inline"
        :state="formItemState"
        :id="widget.name"
      >
        <b-form-checkbox
          :value="item.value"
          v-for="(item, index) in widget.options.options"
          :key="item.value + index"
        >
          {{ widget.options.showLabel ? item.label : item.value }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </template>

    <template v-if="widget.type == 'date'">
      <template v-if="elementAsView">
        <div class="form-control-view">{{ dataModel }}</div>
      </template>
      <b-form-datepicker
        v-else
        v-model="dataModel"
        :placeholder="widget.options.placeholder"
        :readonly="widget.options.readonly"
        :disabled="widget.options.disabled"
        :style="{ width: widget.options.width }"
        :state="formItemState"
        :id="widget.name"
      >
      </b-form-datepicker>
    </template>

    <template v-if="widget.type == 'select'">
      <template v-if="elementAsView">
        <div class="form-control-view">{{ selectAsView }}</div>
      </template>
      <b-form-select
        v-else
        v-model="dataModel"
        :disabled="widget.options.disabled"
        :style="{ width: widget.options.width }"
        :state="formItemState"
        :id="widget.name"
      >
        <b-form-select-option
          v-for="item in widget.options.options"
          :key="item.value"
          :value="item.value"
          >{{
            widget.options.showLabel ? item.label : item.value
          }}</b-form-select-option
        >
      </b-form-select>
    </template>

    <template v-if="widget.type == 'switch'">
      <b-form-checkbox
        v-model="dataModel"
        :disabled="widget.options.disabled"
        switch
      >
      </b-form-checkbox>
    </template>

    <template v-if="widget.type == 'editor'">
      <vue-editor
        v-model="dataModel"
        :style="{ width: widget.options.width }"
      >
      </vue-editor>
    </template>

    <template v-if="widget.type == 'text'">
      <span :class="[widget.options.fontSize, { 'font-weight-bold': widget.options.isBold }]">{{ dataModel }}</span>
    </template>

    <template v-if="widget.type == 'html'">
      <div class="ql-snow">
        <div class="ql-editor" v-html="widget.options.defaultValue"></div>
      </div>
    </template>

    <template v-if="widget.type == 'divider'">
      <hr/>
    </template>
  </b-form-group>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  inheritAttrs: false,
  props: {
    widget: Object,
    models: Object,
    rules: Object,
    validate: {
        type: Boolean,
        default: false,
    },
    validation: {
        type: [Boolean, null],
        default: null
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    partialEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueEditor,
  },
  computed: {
    isVisible() {
      if (!this.widget?.isConditioned) return true;
      const [name, value] = this.widget?.condition.split('=');
      return this.models[name] === value;
    },
    radioAsView() {
      return this.widget?.options.options.find((el) => el.value === this.dataModel)?.label ?? '';
    },
    checkboxAsView() {
      return this.widget?.options.options.filter((el) => this.dataModel.includes(el.value)).map((el) => el.value);
    },
    selectAsView() {
      let elem = this.widget?.options.options.find((el) => el.value === this.dataModel);
      return elem?.label ?? this.dataModel;
    },
    elementAsView() {
      return this.viewMode && !this.partialEdit;
    },
    dataModel: {
      get() {
        if (this.widget.type === 'select') {
          let elem = this.widget?.options.options.find((el) => el.label === this.models[this.widget.name]);
          return elem?.value ?? this.models[this.widget.name];
        } else {
          return this.models?.[this.widget.name];
        }
      },
      set(newValue) {
        this.models[this.widget.name] = newValue;
        this.$emit("update:models", {
          ...this.models,
          [this.widget.name]: newValue,
        });
        this.$emit("input-change", newValue, this.widget.name);
      }
    },
    formItemState() {
        return this.validation;
    },
    invalidFeedback() {
        return this.$t('VALIDATION.REQUIRED_FIELD');
    }
  },
};
</script>
