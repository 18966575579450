<template>
    <div>
        <st-modal
            id="beneficiary-view-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            hideable
            size="md"
            :noCloseOnBackdrop="true"
            ref="modal"
            customClass="form-modal view-modal"
        >
            <template #header>
                <div class="w-100 d-flex pb-2 border-bottom align-items-center pb-4">
                    <div class="col-md-7 px-0">
                        <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                            {{ modalTitle }}
                        </p>
                    </div>
                    <div class="col-md-5 px-0">
                        <div class="d-flex justify-content-end">
                            <div class="view-item arrow left" @click="goLeft">
                                <i class="fas fa-chevron-left"></i>
                            </div>
                            <div class="view-item middle">
                                Beneficiar {{ currentIndex + 1 }} din
                                {{ items.length }}
                            </div>
                            <div class="view-item arrow right" @click="goRight">
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #body>
                <beneficiary-details :item="currentBeneficiary"></beneficiary-details>
                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="hide">
                        {{ $t("APPLICATION.MODALS.CANCEL") }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import BeneficiaryDetails from '@/modules/applications/components/view-application/BeneficiaryDetails';

export default {
    name: "BeneficiaryViewModal",
    components: {
        BeneficiaryDetails
    },
    props: {
        items: {
            type: Array,
            required: false,
        },
        index: {
            type: Number,
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    computed: {
        modalTitle() {
            return `${this.currentBeneficiary.first_name} ${this.currentBeneficiary.last_name}`;
        },
        currentBeneficiary() {
            return this.items[this.currentIndex];
        },
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
        goRight() {
            if (this.currentIndex + 1 < this.items.length) {
                this.currentIndex = this.currentIndex + 1;
            } else {
                this.currentIndex = 0;
            }
        },
        goLeft() {
            if (this.currentIndex + 1 >= this.items.length - 1) {
                this.currentIndex = this.currentIndex - 1;
                if (this.currentIndex < 0) {
                    this.currentIndex = 0;
                }
            } else {
                this.currentIndex = this.items.length - 1;
            }
        },
        addBeneficiaryDetails() {
            this.items.map((el) => {
                if (el.user_type_name === 'foreigner') {
                    el.citizenship = this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.FOREIGNER");
                } else {
                    el.citizenship = this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.ROMANIAN");
                }

                if (el.user_type_name === "legal_entity") {
                    el.person = this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL");
                } else {
                    el.person = this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL");
                }
            });
        }
    },
    mounted() {
        // Initialize the form after the modal is shown
        this.$root.$on("bv::modal::shown", (modalObj) => {
            if (modalObj.componentId === "beneficiary-view-modal") {
                this.currentIndex = this.index;
            }

            this.addBeneficiaryDetails();
        });
    },
};
</script>
