<template>
    <div class="mb-4">
        <div class="row justify-content-start mb-4">
            <!-- Remove condition once foreigner option is decided to be available -->
            <div class="col-4" v-if="false">
                <p class="font-size-h6">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.HEADER") }}
                </p>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedCitizenship"
                    :options="citizenship"
                    name="radio-options-citizenship"
                    :disabled="viewMode"
                ></b-form-radio-group>
            </div>
            <div class="col-4">
                <p class="font-size-h6">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.HEADER") }}
                </p>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedPerson"
                    :options="person"
                    name="radio-options-person"
                    :disabled="viewMode"
                ></b-form-radio-group>
            </div>
        </div>

        <div v-if="selectedCitizenship === 'foreigner'">
            <foreigner-beneficiary-form
                :item="item"
                ref="foreignerBeneficiary"
                @change="onModelChange"
                :viewMode="viewMode"
            >
            </foreigner-beneficiary-form>
        </div>
        <div v-else>
            <citizen-beneficiary-form
                v-if="selectedPerson === 'citizen'"
                :item="item"
                ref="citizenBeneficiary"
                :viewMode="viewMode"
            >
            </citizen-beneficiary-form>
            <legal-beneficiary-form
                v-if="selectedPerson === 'legal_entity'"
                ref="legalBeneficiary"
                :item="item"
                :viewMode="viewMode"
            >
            </legal-beneficiary-form>
        </div>

        <div class="mt-6">
            <slot name="buttons" :submit="submit" :cancel="cancel" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CitizenBeneficiaryForm from "./CitizenBeneficiaryForm";
import ForeignerBeneficiaryForm from "./ForeignerBeneficiaryForm";
import LegalBeneficiaryForm from "./LegalBeneficiaryForm";

export default {
    name: "BeneficiaryFormTypes",
    components: {
        CitizenBeneficiaryForm,
        ForeignerBeneficiaryForm,
        LegalBeneficiaryForm,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
    },
    data() {
        return {
            citizenship: [
                {
                    text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.ROMANIAN"),
                    value: "citizen",
                },
                {
                    text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.FOREIGNER"),
                    value: "foreigner",
                },
            ],
            selectedCitizenship: "citizen",
            selectedPerson: "citizen",
        };
    },
    watch: {
        selectedCitizenship(value) {
            if (value === 'foreigner') this.selectedPerson = 'citizen';
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            record: "applications/form/record",
        }),
        person() {
            const options = [{
                text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL"),
                value: "citizen",
            }];

            if (this.selectedCitizenship === "citizen") {
                options.push({
                    text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL"),
                    value: "legal_entity",
                });
            }

            return options;
        }
    },
    mounted() {
        if (this.viewMode || this.item) {
            this.selectedPerson = this.item.user_type_name === "foreigner" ? "citizen" : this.item.user_type_name;
            this.selectedCitizenship = this.item.user_type_name !== "foreigner" ? "citizen" : "foreigner";
        } else {
            this.selectedPerson = this.currentUser.user_type_name === "foreigner" ? "citizen" : this.currentUser.user_type_name;
            this.selectedCitizenship = this.currentUser.user_type_name !== "foreigner" ? "citizen" : "foreigner";
        }
    },
    methods: {
        submit() {
            this.$emit("submit");
        },
        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>
